<template>
  <div class="bg-white h-screen">
    <loading-overlay :visible="loading" />
    <transition name="fadeslow">
      <div
        v-if="notification.visible"
        class="flex justify-center absolute w-full z-30 my-4 cursor-pointer"
        @click="
          clear_interval(notification.interval);
          notification.visible = false;
        "
        @mouseover="clear_interval(notification.interval)"
        @mouseleave="notification.visible = false"
        :class="{ ar: $i18n.locale == 'ar', en: $i18n.locale != 'ar' }"
      >
        <div class="flex space-x-2 justify-center">
          <div
            class="bg-white shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block"
            id="static-example"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-mdb-autohide="false"
          >
            <div
              class="p-3 rounded-lg break-words text-white text-2xl"
              :style="`background-color: ${notification.color}`"
            >
              {{ notification.message }}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="w-full flex relative justify-center items-center">
      <!-- Image Section -->
      <div class="object-cover w-full h-screen bg-white"></div>
      <svg
        class="absolute left-0 bottom-0 h-4/5"
        viewBox="0 0 305 828"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1086_2552"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="305"
          height="828"
        >
          <rect width="305" height="828" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_1086_2552)">
          <mask
            id="mask1_1086_2552"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="-66"
            y="-181"
            width="355"
            height="1080"
          >
            <rect
              x="289"
              y="899"
              width="355"
              height="1080"
              transform="rotate(-180 289 899)"
              fill="#C4C4C4"
            />
          </mask>
          <g mask="url(#mask1_1086_2552)">
            <path
              d="M148.293 450.484L-86.9279 1131.19C-107.187 1189.82 -193.585 1176.91 -195.821 1114.92L-226.192 272.644C-227.221 244.105 -206.61 219.363 -178.355 215.219L-70.4075 199.386C-51.7419 196.649 -32.9524 203.493 -20.421 217.595L137.224 394.996C150.674 410.131 154.906 431.347 148.293 450.484Z"
              fill="#404150"
            />
            <path
              d="M169.192 278.152L-23.5519 783.574C-67.3552 898.437 -237.157 870.637 -242.046 747.803L-267.904 98.0062C-270.225 39.6817 -227.742 -10.8123 -169.88 -18.5021L-101.746 -27.557C-66.4893 -32.2426 -31.0843 -20.0557 -6.18125 5.33738L144.287 158.767C175.194 190.282 184.921 236.908 169.192 278.152Z"
              fill="#5357ac"
            />
          </g>
        </g>
      </svg>
      <svg
        class="absolute right-0 top-0 h-4/5 transform rotate-180"
        viewBox="0 0 305 828"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1086_2552"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="305"
          height="828"
        >
          <rect width="305" height="828" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_1086_2552)">
          <mask
            id="mask1_1086_2552"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="-66"
            y="-181"
            width="355"
            height="1080"
          >
            <rect
              x="289"
              y="899"
              width="355"
              height="1080"
              transform="rotate(-180 289 899)"
              fill="#C4C4C4"
            />
          </mask>
          <g mask="url(#mask1_1086_2552)">
            <path
              d="M148.293 450.484L-86.9279 1131.19C-107.187 1189.82 -193.585 1176.91 -195.821 1114.92L-226.192 272.644C-227.221 244.105 -206.61 219.363 -178.355 215.219L-70.4075 199.386C-51.7419 196.649 -32.9524 203.493 -20.421 217.595L137.224 394.996C150.674 410.131 154.906 431.347 148.293 450.484Z"
              fill="#404150"
            />
            <path
              d="M169.192 278.152L-23.5519 783.574C-67.3552 898.437 -237.157 870.637 -242.046 747.803L-267.904 98.0062C-270.225 39.6817 -227.742 -10.8123 -169.88 -18.5021L-101.746 -27.557C-66.4893 -32.2426 -31.0843 -20.0557 -6.18125 5.33738L144.287 158.767C175.194 190.282 184.921 236.908 169.192 278.152Z"
              fill="#5357ac"
            />
          </g>
        </g>
      </svg>
      <form
        class="absolute mx-auto w-full h-auto my-auto md:h-auto md:w-2/5 bg-high-login-dash shadow-xl rounded-xl flex flex-col justify-start items-center"
        @submit.prevent="handleSubmit"
      >
        <div
          class="h-4 bg-high-login-dash w-full rounded-t-xl md:bg-ssr-side_bar-button"
        ></div>
        <div
          v-if="enabled"
          class="flex flex-col w-full justify-center items-center p-12"
        >
          <img src="@/assets/logo_full2.svg" class="w-2/3 mb-4" alt="Logo" />
          <!-- <div
            class="text-xl md:text-2xl text-high-login-overlay font-body font-extrabold"
          >
            {{ $t("login.title") }}
          </div> -->
          <div
            v-if="licensed && !no_realm"
            class="text-left text-gray-700 w-full"
          >
            {{ $t("login.realm") }}
          </div>
          <input
            v-if="licensed && !no_realm"
            v-model="realm"
            type="text"
            id="realm"
            class="bg-white rounded-lg w-full h-8 pl-2 shadow-md"
          />
          <div v-if="licensed" class="text-left text-gray-700 w-full mt-4">
            {{ $t("login.email") }}
          </div>
          <input
            v-if="licensed"
            v-model="email"
            type="text"
            id="email"
            class="bg-white rounded-lg w-full h-8 pl-2 shadow-md"
          />
          <div v-if="licensed" class="text-left text-gray-700 w-full mt-4">
            {{ $t("login.password") }}
          </div>
          <input
            v-if="licensed"
            v-model="password"
            type="password"
            id="password"
            class="bg-white rounded-lg w-full h-8 pl-2 shadow-md"
          />
          <div v-if="!licensed" class="text-left text-gray-700 w-full mt-4">
            {{ $t("login.serial_number") }}
          </div>
          <div v-if="!licensed" class="flex justify-center space-x-2">
            <input
              v-model="serial.first"
              type="serial"
              id="serial"
              class="bg-white rounded-lg w-full h-8 shadow-md text-center"
              placeholder="xxxx"
            />
            <div class="text-2xl font-bold">-</div>
            <input
              v-model="serial.second"
              type="serial"
              id="serial"
              class="bg-white rounded-lg w-full h-8 shadow-md text-center"
              placeholder="xxxx"
            />
            <div class="text-2xl font-bold">-</div>
            <input
              v-model="serial.third"
              type="serial"
              id="serial"
              class="bg-white rounded-lg w-full h-8 shadow-md text-center"
              placeholder="xxxx"
            />
            <div class="text-2xl font-bold">-</div>
            <input
              v-model="serial.forth"
              type="serial"
              id="serial"
              class="bg-white rounded-lg w-full h-8 shadow-md text-center"
              placeholder="xxxx"
            />
          </div>
          <div
            v-if="formError"
            class="rounded-lg w-full h-8 pl-2 mt-4 text-red-500 text-xl font-bold text-center"
          >
            {{ errorMessage }}
          </div>
          <button
            v-if="licensed"
            class="bg-ssr-side_bar-settings text-white font-bold rounded-lg w-full mt-6 h-8"
          >
            {{ $t("login.login") }}
          </button>
          <div
            @click.prevent="activateLicense"
            v-if="!licensed"
            class="bg-ssr-side_bar-settings text-white font-bold rounded-lg w-full mt-6 cursor-pointer h-8 text-center"
          >
            {{ $t("login.license") }}
          </div>
        </div>
        <div
          v-else
          class="flex flex-col w-full justify-center items-center p-12"
        >
          INTERNAL SERVER ERROR
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
export default {
  components: { LoadingOverlay },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      realm: "",
      formError: false,
      errorMessage: "",
      loading: false,
      licensed: true,
      enabled: true,
      no_realm: false,
      serial: {
        first: "",
        second: "",
        third: "",
        forth: "",
      },
      notification: {
        visible: false,
        color: "#45cc45",
        message: "Activation Success",
        interval: null,
      },
    };
  },
  created() {
    // if (localStorage.getItem("accounts_filters") != null)
    //   localStorage.removeItem("accounts_filters");
    // if (localStorage.getItem("admins_filters") != null)
    //   localStorage.removeItem("admins_filters");
    // if (localStorage.getItem("batches_filters") != null)
    //   localStorage.removeItem("batches_filters");
    // if (localStorage.getItem("cards_filters") != null)
    //   localStorage.removeItem("cards_filters");
    // if (localStorage.getItem("nases_filters") != null)
    //   localStorage.removeItem("nases_filters");
    // if (localStorage.getItem("notifications_filters") != null)
    //   localStorage.removeItem("notifications_filters");
    // if (localStorage.getItem("online_filters") != null)
    //   localStorage.removeItem("online_filters");
    // if (localStorage.getItem("profiles_filters") != null)
    //   localStorage.removeItem("profiles_filters");
    // if (localStorage.getItem("resellers_filters") != null)
    //   localStorage.removeItem("resellers_filters");
    // if (localStorage.getItem("sessions_filters") != null)
    //   localStorage.removeItem("sessions_filters");
    // if (localStorage.getItem("support_tickets_filters") != null)
    //   localStorage.removeItem("support_tickets_filters");
  },
  mounted() {
    if (localStorage.getItem("realm")) {
      this.realm = localStorage.getItem("realm");
    }
    this.checkLicense();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.no_realm && this.realm != "local" && this.realm != "radius") {
        var auth_url = "https://auth.ssradius.com/";
        const keyResponse = await axios.post(auth_url + "api/key", {
          token: "equlize123$$$$",
          realm: this.realm,
        });
        if (keyResponse.status == 200) {
          localStorage.setItem("server", keyResponse.data.data.server);
          localStorage.setItem(
            "server_api",
            keyResponse.data.data.server + "api/"
          );
        } else if (keyResponse.status == 204) {
          this.formError = true;
          this.loading = false;
          this.errorMessage = "Realm Disabled";
          return;
        } else {
          this.formError = true;
          this.loading = false;
          this.errorMessage = "Invalid Realm";
          return;
        }
      }
      if (this.realm == "local") {
        localStorage.setItem("server", "http://localhost:8000/");
        localStorage.setItem("server_api", "http://localhost:8000/api/");
      }
      if (this.realm == "radius") {
        localStorage.setItem("server", "http://ssradius.test/server/");
        localStorage.setItem("server_api", "http://ssradius.test/server/api/");
      }
      if (this.realm == "test") {
        localStorage.setItem("server", "https://s1.ssradius.com/864444/");
        localStorage.setItem(
          "server_api",
          "https://s1.ssradius.com/864444/api/"
        );
      }
      if (this.no_realm) {
        const curr_url = window.location.origin;
        localStorage.setItem("server", curr_url + "/server/");
        localStorage.setItem("server_api", curr_url + "/server/api/");
      }
      axios.defaults.baseURL = localStorage.getItem("server_api");

      try {
        // const res = await axios.post("users/login", {
        //   email: this.email,
        //   password: this.password,
        // });
        // localStorage.setItem("token", res.data.token);
        // axios.defaults.headers.common["Authorization"] =
        //   "Bearer " + localStorage.getItem("token");
        var cookieRes = await axios.get(
          localStorage.getItem("server") + "sanctum/csrf-cookie"
        );
        if (cookieRes.status == 204 || cookieRes.status == 200) {
          const loginRes = await axios.post("/users/login", {
            email: this.email,
            password: this.password,
          });
          if (loginRes.status == 210) {
            this.formError = true;
            this.loading = false;
            this.errorMessage = "Invalid Username or Password";
            return;
          } else if (loginRes.status == 261) {
            this.formError = true;
            this.loading = false;
            this.errorMessage = "License Error";
            return;
          } else if (loginRes.status == 269) {
            this.formError = true;
            this.loading = false;
            this.errorMessage = "Check internet connection";
            return;
          } else if (loginRes.status == 200) {
            const responseSettings = await axios.get("settings");
            this.$store.dispatch("settings", responseSettings.data.data);
            this.$i18n.locale = responseSettings.data.data.language;
            const responseUser = await axios.get("user");
            localStorage.setItem("realm", this.realm);
            this.$store.dispatch("user", responseUser.data);
            this.$router.push("/dashboard");
            this.loading = false;
          } else if (loginRes.response && loginRes.response.status == 500) {
            this.formError = true;
            this.loading = false;
            this.errorMessage = loginRes.response.data.message;
          }
        }
      } catch (e) {
        this.formError = true;
        this.loading = false;
        this.errorMessage = "SERVER ERROR";
        console.log(e);
      }
    },
    async checkLicense() {
      const curr_url = window.location.origin;
      if (
        curr_url == "http://localhost:8080" ||
        curr_url == "https://s1.ssradius.com" ||
        curr_url == "https://s2.ssradius.com"
      )
        return;
      try {
        const response = await axios.get(curr_url + "/server/api/check");
        switch (response.status) {
          case 200:
            this.licensed = true;
            if (response.data == "licensed") {
              this.no_realm = true;
            }
            break;
          case 230:
            this.licensed = false;
            this.notification.message = "No License Found";
            this.notification.color = "#FF9700";
            this.notification.visible = true;
            this.notification.interval = setTimeout(async () => {
              this.notification.visible = false;
            }, 7000);
            break;
          case 235:
            this.licensed = false;
            this.notification.message = "License Error";
            this.notification.color = "#FF2D00";
            this.notification.visible = true;
            this.notification.interval = setTimeout(async () => {
              this.notification.visible = false;
            }, 7000);
            break;
          case 250:
            this.notification.message = "DB Connection Failed!";
            this.notification.color = "#FF2D00";
            this.notification.visible = true;
            this.notification.interval = setTimeout(async () => {
              this.notification.visible = false;
            }, 7000);
            break;
          case 500:
            this.notification.message = response.data;
            this.notification.color = "#FF2D00";
            this.notification.visible = true;
            this.notification.interval = setTimeout(async () => {
              this.notification.visible = false;
            }, 7000);
            this.enabled = false;
            break;
        }
        if (response.response && response.response.status == 500) {
          this.notification.message = response.response.data.substring(0, 100);
          this.notification.color = "#FF2D00";
          this.notification.visible = true;
          this.notification.interval = setTimeout(async () => {
            this.notification.visible = false;
          }, 7000);
          this.enabled = false;
        }
      } catch (error) {
        this.notification.message = "INTERNAL SERVER ERROR!";
        this.notification.color = "#FF2D00";
        this.notification.visible = true;
        this.notification.interval = setTimeout(async () => {
          this.notification.visible = false;
        }, 7000);
        this.enabled = false;
      }
    },
    async activateLicense() {
      const curr_url = window.location.origin;
      try {
        const serial =
          this.serial.first +
          "-" +
          this.serial.second +
          "-" +
          this.serial.third +
          "-" +
          this.serial.forth;
        const response = await axios.post(curr_url + "/server/api/activate", {
          serial: serial,
        });
        if (response.status == 200) {
          this.notification.message = "Activated Successfully";
          this.notification.color = "#45cc45";
          this.notification.visible = true;
          this.notification.interval = setTimeout(async () => {
            this.notification.visible = false;
          }, 7000);
          this.licensed = true;
        } else {
          this.licensed = false;
          this.notification.message = "License Error";
          this.notification.color = "#FF2D00";
          this.notification.visible = true;
          this.notification.interval = setTimeout(async () => {
            this.notification.visible = false;
          }, 7000);
        }
      } catch (error) {
        this.licensed = false;
        this.notification.message = "Error Connecting to verfication server";
        this.notification.color = "#FF2D00";
        this.notification.visible = true;
        this.notification.interval = setTimeout(async () => {
          this.notification.visible = false;
        }, 7000);
      }
      await this.checkLicense();
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
  },
};
</script>
