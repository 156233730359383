<template>
  <div class="main-content flex-1 h-full bg-transparent md:pb-5">
    <div class="flex relative">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-y-8 gap-x-16 flex-1 m-8">
        <!-- <div
          class="md:col-span-4 flex flex-col justify-center items-center text-2xl"
        >
          <div>{{ currentDate }}</div>
          <div>{{ currentTime }}</div>
        </div> -->
        <dashboard-card
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('cards_view_index_cards') &&
              hasPrivilege('accounts_view_index_accounts')
          "
          class="col-start-1"
          :color="'#3498d8'"
          :title="$t('dashboard.statistics.total_users')"
          :value="users.total_users"
          :details="$t('dashboard.statistics.total_users')"
          :only_values="true"
        >
          <profiles-icon class="w-14 h-14" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/accounts' })"
          :color="'#ec255a'"
          :title="$t('dashboard.statistics.total_accounts')"
          :value="users.total_accounts"
          :details="$t('dashboard.statistics.total_accounts')"
        >
          <accounts-icon class="w-14 h-14" style="color: #ec255a" />
        </dashboard-card>

        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/cards/cards' })"
          :color="'#e74b75'"
          :title="$t('dashboard.statistics.total_cards')"
          :value="users.total_cards"
          :details="$t('dashboard.statistics.total_cards')"
        >
          <cards-icon class="w-14 h-14" style="color: #ec255a" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$store.commit('changeKey');
            this.$router.push({
              path: '/accounts',
              query: { type: 'expiring' },
            });
          "
          :color="'#ffc000'"
          :title="$t('dashboard.statistics.expiring_accounts')"
          :value="users.expiring_accounts"
          :details="$t('dashboard.statistics.expiring_accounts')"
        >
          <warning-icon class="w-14 h-14" style="color: #ffc000" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/sessions/online' })"
          :color="'#25c76f'"
          :title="$t('dashboard.statistics.total_online')"
          :value="users.total_online"
          :details="$t('dashboard.statistics.total_online')"
        >
          <online-icon class="w-14 h-14" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$store.commit('changeKey');
            this.$router.push({ path: '/accounts', query: { type: 'online' } });
          "
          :color="'#25c76f'"
          :title="$t('dashboard.statistics.online_accounts')"
          :value="users.online_accounts"
          :details="$t('dashboard.statistics.online_accounts')"
        >
          <accounts-icon class="w-14 h-14" style="color: #25c76f" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$store.commit('changeKey');
            this.$router.push({
              path: '/cards/cards',
              query: { type: 'online' },
            });
          "
          :color="'#25c76f'"
          :title="$t('dashboard.statistics.online_cards')"
          :value="users.online_cards"
          :details="$t('dashboard.statistics.online_cards')"
        >
          <cards-icon class="w-14 h-14" style="color: #25c76f" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$store.commit('changeKey');
            this.$router.push({
              path: '/accounts',
              query: { type: 'expired' },
            });
          "
          :color="'#ff5c00'"
          :title="$t('dashboard.statistics.expired_accounts')"
          :value="users.expired_accounts"
          :details="$t('dashboard.statistics.expired_accounts')"
        >
          <warning-icon class="w-14 h-14" style="color: #ff5c00" />
        </dashboard-card>
        <dashboard-card
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$store.commit('changeKey');
            this.$router.push({
              path: '/accounts',
              query: { type: 'expiration_profile' },
            });
          "
          :color="'#ff5c00'"
          :title="$t('dashboard.statistics.expiration_profile_accounts')"
          :value="users.expiration_profile_accounts"
          :details="$t('profiles.expiration_profile')"
        >
          <warning-icon class="w-14 h-14" style="color: #ff5c00" />
        </dashboard-card>
        <dashboard-card
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('support_tickets_view_index_support_tickets') &&
              settings.features.ssra
          "
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/services/support' })"
          :color="'#ff5c00'"
          :title="$t('dashboard.statistics.support_tickets')"
          :value="users.support_tickets"
          :details="$t('dashboard.statistics.support_tickets')"
        >
          <support-icon class="w-14 h-14" style="color: #ff5c00" />
        </dashboard-card>

        <dashboard-card
          v-if="
            user.data.type != 'reseller' &&
              hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('profiles_view_index_profiles')
          "
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/profiles' })"
          :color="'#3498d8'"
          :title="$t('dashboard.statistics.profiles')"
          :value="users.profiles"
          :details="$t('dashboard.statistics.profiles')"
        >
          <profiles-icon class="w-14 h-14" />
        </dashboard-card>

        <!-- <dashboard-card
          v-if="
            user.data.type != 'reseller' && hasPrivilege('system_ownership')
          "
          class="cursor-pointer hover:shadow-2xl"
          @click="this.$router.push({ path: '/admins' })"
          :color="'#3498d8'"
          :title="$t('dashboard.statistics.managers')"
          :value="users.managers"
          :details="$t('dashboard.statistics.managers')"
        >
          <profiles-icon class="w-14 h-14" />
        </dashboard-card> -->

        <dashboard-card
          v-if="
            user.data.type != 'reseller' && hasPrivilege('system_ownership')
          "
          class="cursor-pointer hover:shadow-2xl"
          @click="
            this.$router.push({
              path: '/accounts',
              query: { type: 'in_debt' },
            })
          "
          :color="'#3498d8'"
          :title="$t('bills.total.total_debt')"
          :value="users.total_debts"
          :details="$t('bills.total.total_debt')"
        >
          <div class="text-5xl" style="color: #3498d8">
            {{ settings.currency }}
          </div>
        </dashboard-card>

        <dashboard-card
          v-if="user.data.type == 'reseller'"
          class="col-start-1"
          :color="'#25c76f'"
          :title="$t('dashboard.statistics.money')"
          :value="user.data.money"
          :details="$t('dashboard.statistics.money')"
        >
          <div class="text-5xl">{{ settings.currency }}</div>
        </dashboard-card>

        <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              this.settings.sessions.available > 0
          "
          class="md:col-span-2"
          :color="'#e74b75'"
          :title="$t('license.remaining')"
        >
          <online-chart
            v-if="sessions.visible"
            :online="sessions.online"
            :remaining="sessions.remaining"
          />
          <div v-else class="h-40"></div>
        </dashboard-card-big>
        <div
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              settings.type == 'local'
          "
          class="flex flex-col md:col-span-2 rounded-3xl border-2 text-high-dashboard-blue-users shadow-dashboard bg-white justify-center items-center border-ssr-side_bar-button_hover relative"
        >
          <div class="flex justify-center items-center mt-2 absolute top-0">
            <div class="text-2xl text-ssr-side_bar-button_hover font-bold">
              {{ $t("dashboard.system_usage") }}
            </div>
          </div>
          <div class="flex justify-between p-12 gap-4 w-full">
            <pie-chart :value="system.ram_use" />
            <pie-chart :value="system.cpu_use" />
            <pie-chart :value="system.disk_use" />
          </div>
          <div class="flex justify-around px-12 gap-4 w-full absolute text-xl">
            <div class="text-center">
              <p>RAM</p>
              <p>%{{ system.ram_use }}</p>
            </div>
            <div class="text-center">
              <p>CPU</p>
              <p>%{{ system.cpu_use }}</p>
            </div>
            <div class="text-center">
              <p>HARD</p>
              <p>%{{ system.disk_use }}</p>
            </div>
          </div>
        </div>
        <div
          class="md:col-span-2 flex flex-col justify-around items-center"
          v-if="this.settings.sessions.available > 0"
        >
          <img src="@/assets/logo_full2.svg" class="w-2/3" alt="Logo" />
          <div class="text-5xl font-bold">
            {{ this.settings.license.realm }}
          </div>
          <div v-if="settings.license.realm != 'local'" class="text-3xl">
            {{ formatDate(this.settings.created_at) }}
          </div>
          <div v-if="settings.license.realm == 'local'" class="text-3xl">
            {{ this.settings.update_name }}
          </div>
        </div>

        <!-- <div
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="
            flex flex-col
            md:col-span-2
            rounded-3xl
            border-2
            text-high-dashboard-blue-users
            shadow-dashboard
            bg-white
            justify-center
            items-center
            border-ssr-side_bar-button_hover
          "
        >
          <div class="flex justify-center items-center mt-2">
            <div class="text-2xl text-ssr-side_bar-button_hover font-bold">
              {{ $t("dashboard.new_users") }}
            </div>
          </div>
          <new-users-chart
            v-if="new_users"
            :x_data="new_users.x_data"
            :y_data="new_users.y_data"
            :title="'new_users'"
            class="w-full px-16"
          />
          <img
            v-if="!new_users"
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
          <div class="flex justify-around w-full items-center mb-2">
            <div
              class="
                rounded-full
                bg-blue-200
                text-blue-600
                font-bold
                px-2
                cursor-pointer
              "
              @click="fetchNewUsersValues('24_hour')"
            >
              {{ $t("control.hours_24") }}
            </div>
            <div
              class="
                rounded-full
                bg-blue-200
                text-blue-600
                font-bold
                px-2
                cursor-pointer
              "
              @click="fetchNewUsersValues('1_month')"
            >
              {{ $t("control.months_1") }}
            </div>
            <div
              class="
                rounded-full
                bg-blue-200
                text-blue-600
                font-bold
                px-2
                cursor-pointer
              "
              @click="fetchNewUsersValues('6_months')"
            >
              {{ $t("control.months_6") }}
            </div>
            <div
              class="
                rounded-full
                bg-blue-200
                text-blue-600
                font-bold
                px-2
                cursor-pointer
              "
              @click="fetchNewUsersValues('1_year')"
            >
              {{ $t("control.years_1") }}
            </div>
          </div>
        </div> -->
        <!-- <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('accounting_view_account_debts')
          "
          class="md:col-span-2"
          :color="'#e74b75'"
          :title="$t('dashboard.statistics.debt_accounts')"
        >
          <debt-accounts class="w-full h-52 overflow-hidden" />
        </dashboard-card-big> -->
        <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('accounts_view_index_accounts')
          "
          class="md:col-span-2 md:col-start-1"
          :color="'#e74b75'"
          :title="$t('dashboard.statistics.expiring_accounts')"
        >
          <ending-accounts-table class="w-full h-52 overflow-auto" />
        </dashboard-card-big>
        <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('support_tickets_view_index_support_tickets') &&
              settings.features.ssra
          "
          class="md:col-span-2"
          :color="'#ff5c00'"
          :title="$t('dashboard.statistics.support_tickets')"
        >
          <support-tickets-table class="w-full h-52 overflow-auto" />
        </dashboard-card-big>
        <!-- <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('support_tickets_view_index_support_tickets') &&
              settings.features.ssra
          "
          class="md:col-span-2"
          :color="'#ff5c00'"
          :title="$t('dashboard.statistics.expiration_profile_accounts')"
        >
          <ExpirationProfileAccountsTable class="w-full h-52 overflow-auto" />
        </dashboard-card-big> -->
        <!-- <dashboard-card-big
          v-if="
            hasPrivilege('dashboard_view_statistics') &&
              hasPrivilege('profiles_view_index_profiles')
          "
          class="md:col-span-2"
          :color="'#e74b75'"
          :title="$t('sidebar.profiles.profiles')"
        >
          <profiles-table class="w-full h-52 overflow-auto" />
        </dashboard-card-big> -->

        <div class="md:col-span-2" v-for="nas in nases" v-bind:key="nas.id">
          <nas-pill :nas="nas" />
        </div>
        <div
          v-if="hasPrivilege('dashboard_view_statistics')"
          class="flex flex-col md:col-start-1 md:col-span-2 rounded-3xl border-2 text-high-dashboard-blue-users shadow-dashboard bg-white justify-center items-center border-ssr-side_bar-button_hover"
        >
          <div class="flex justify-center items-center mt-2">
            <div class="text-2xl text-ssr-side_bar-button_hover font-bold">
              {{ $t("dashboard.logins") }}
            </div>
          </div>
          <logins-chart
            v-if="logins"
            :x_data="logins.x_data"
            :y_data="logins.y_data"
            :title="'logins'"
            class="w-full px-16"
          />
          <img
            v-if="!logins"
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
          <div class="flex justify-around w-full items-center mb-2">
            <div
              class="rounded-full bg-blue-200 text-blue-600 font-bold px-2 cursor-pointer"
              @click="fetchLoginsValues('24_hour')"
            >
              {{ $t("control.hours_24") }}
            </div>
            <div
              class="rounded-full bg-blue-200 text-blue-600 font-bold px-2 cursor-pointer"
              @click="fetchLoginsValues('1_month')"
            >
              {{ $t("control.months_1") }}
            </div>
            <div
              class="rounded-full bg-blue-200 text-blue-600 font-bold px-2 cursor-pointer"
              @click="fetchLoginsValues('6_months')"
            >
              {{ $t("control.months_6") }}
            </div>
            <div
              class="rounded-full bg-blue-200 text-blue-600 font-bold px-2 cursor-pointer"
              @click="fetchLoginsValues('1_year')"
            >
              {{ $t("control.years_1") }}
            </div>
          </div>
        </div>

        <div
          class="md:col-span-2 flex flex-col justify-center items-center"
          v-if="this.settings.sessions.available <= 0"
        >
          <img
            src="@/assets/logo_full2.svg"
            class="md:w-1/2 w-1/2"
            alt="Logo"
          />
          <div class="text-3xl">{{ formatDate(this.settings.created_at) }}</div>
          <div v-if="settings.license.realm == 'local'" class="text-3xl">
            {{ this.settings.update_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import PillDefault from "../components/PillDefault.vue";
import UsersPill from "../components/UsersPill.vue";
import TrafficPill from "../components/TrafficPill.vue";
import moment from "moment";
import TimePill from "../components/TimePill.vue";
import LoadPill from "../components/LoadPill.vue";
import axios from "axios";
import PieChart from "../components/DonutChart.vue";
import RingGauage from "../components/RingGauage.vue";
import "../axios";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import NasPill from "../components/NasPill.vue";
import AccountsIcon from "../components/sub_elements/icons/AccountsIcon.vue";
import DashboardCard from "../components/sub_elements/DashboardCard.vue";
import WarningIcon from "../components/sub_elements/icons/WarningIcon.vue";
import ProfilesIcon from "../components/sub_elements/icons/ProfilesIcon.vue";
import DisabledIcon from "../components/sub_elements/icons/DisabledIcon.vue";
import OnlineIcon from "../components/sub_elements/icons/OnlineIcon.vue";
import CardsIcon from "../components/sub_elements/icons/CardsIcon.vue";
import SupportIcon from "../components/sub_elements/icons/SupportIcon.vue";
import LoginsChart from "../components/sub_elements/LoginsChart.vue";
import NewUsersChart from "../components/sub_elements/NewUsersChart.vue";
import DashboardCardBig from "../components/sub_elements/DashboardCardBig.vue";
import ProfilesTable from "../components/sub_elements/ProfilesTable.vue";
import EndingAccountsTable from "../components/sub_elements/EndingAccountsTable.vue";
import SupportTicketsTable from "../components/sub_elements/SupportTicketsTable.vue";
import OnlineChart from "../components/sub_elements/OnlineChart.vue";
import DebtAccounts from "../components/sub_elements/DebtAccounts.vue";
import ExpirationProfileAccountsTable from "../components/sub_elements/ExpirationProfileAccountsTable.vue";

export default {
  name: "Dashboard",
  components: {
    PillDefault,
    UsersPill,
    TrafficPill,
    TimePill,
    LoadPill,
    PieChart,
    RingGauage,
    NasPill,
    AccountsIcon,
    DashboardCard,
    WarningIcon,
    ProfilesIcon,
    DisabledIcon,
    OnlineIcon,
    CardsIcon,
    SupportIcon,
    LoginsChart,
    NewUsersChart,
    DashboardCardBig,
    ProfilesTable,
    EndingAccountsTable,
    SupportTicketsTable,
    ExpirationProfileAccountsTable,
    OnlineChart,
    DebtAccounts,
  },
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      timer: null,
      nas_timer: null,
      currentDate: null,
      currentTime: null,
      users: {
        active_accounts: "...",
        active_cards: "...",
        disabled_accounts: "...",
        disabled_cards: "...",
        expired_accounts: "...",
        expired_cards: "...",
        expiring_users: "...",
        managers: "...",
        total_online: "...",
        online_accounts: "...",
        online_cards: "...",
        profiles: "...",
        total_accounts: "...",
        total_cards: "...",
        total_users: "...",
        total_debts: "...",
        expiring_cards: "...",
        expiring_accounts: "...",
        support_tickets: "...",
      },
      system: {
        cpu_use: null,
        ram_use: null,
        disk_use: null,
        version: null,
        uptime: null,
      },
      sessions: {
        online: 1,
        remaining: 1,
        visible: false,
      },
      logins: null,
      new_users: null,
      nases: null,
    };
  },
  async mounted() {
    this.sessions.online = this.settings.sessions.online;
    this.sessions.remaining = this.settings.sessions.remaining;
    this.sessions.visible = true;
    this.updateDashboard();
    // setInterval(() => {
    //   this.fetchMTvalues();
    // }, 1000 * 60);
    this.currentDate = moment().format("YYYY-MM-DD");
    this.currentTime = moment().format("hh:mm a");
    await this.fetchLoginsValues();
    if (this.user.data.type != "reseller") {
      this.timer = setInterval(() => {
        this.updateDashboard();
      }, 30000);
      if (this.hasPrivilege("nases_view_index_nases")) {
        await this.fetchNasesValues();
        this.nas_timer = setInterval(() => {
          this.fetchNasesValues();
        }, 1000 * 60 * 3);
      }
    }
    // await this.fetchNewUsersValues();
  },
  watch: {
    $route(to, from) {
      if (this.timer != null) {
        window.clearInterval(this.timer);
        this.timer = null;
        window.clearInterval(this.nas_timer);
        this.nas_timer = null;
      }
    },
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async updateDashboard() {
      this.currentDate = moment().format("YYYY-MM-DD");
      this.currentTime = moment().format("hh:mm a");
      await this.fetchUsersValues();
      await this.fetchSystemVitals();
      await this.fetchSessionsValues();
    },
    async fetchUsersValues() {
      const response = await axios.get("dash/users");
      if (response.status == 200) {
        this.users = response.data.data;
      }
    },
    async fetchSystemVitals() {
      const response = await axios.get("dash/system");
      if (response.status == 200) {
        this.system = response.data.data;
      }
    },
    async fetchNasesValues() {
      const response = await axios.get("nases/values");
      if (response.status == 200) {
        this.nases = response.data.data;
      }
    },
    async fetchLoginsValues(filter = "24_hour") {
      this.logins = null;
      const response = await axios.get(`dash/logins?filter=${filter}`);
      if (response.status == 200) {
        this.logins = response.data.data;
      }
    },
    async fetchNewUsersValues(filter = "24_hour") {
      this.new_users = null;
      const response = await axios.get(`dash/newusers?filter=${filter}`);
      if (response.status == 200) {
        this.new_users = response.data.data;
      }
    },
    async fetchSessionsValues() {
      if (this.settings.license.online_count > 0) {
        this.new_users = null;
        this.sessions.visible = false;
        const response = await axios.get(`settings/sessions`);
        if (response.status == 200) {
          this.sessions.online = response.data.data.online;
          this.sessions.remaining = response.data.data.remaining;
          this.sessions.visible = true;
        }
      }
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>
