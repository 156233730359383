<template>
  <div class="main-content flex-1 bg-gray-100">
    <loading-overlay :loading="loading_overlay" />
    <div class="p-2 md:p-6">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th>
              <div class="inline-block">{{ $t("admins_log.id") }}</div>
            </th>
            <th v-if="hasPrivilege('system_ownership')">
              <div>{{ $t("admins_log.user") }}</div>
              <select
                @change="applyFilters"
                v-model="filters.user_id"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-2/3
                "
              >
                <option value="-1">{{ $t("control.all") }}</option>
                <option
                  v-for="ft in admins"
                  v-bind:key="ft.id"
                  :value="ft.id"
                  :class="{
                    'text-green-400 text-center': ft.type == 'reseller',
                  }"
                >
                  {{ ft.email }}
                </option>
              </select>
            </th>
            <th>
              <div>{{ $t("admins_log.operation") }}</div>
              <select
                @change="applyFilters"
                v-model="filters.operation"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-2/3
                "
              >
                <option value="">{{ $t("control.all") }}</option>
                <option v-for="ft in operations" v-bind:key="ft" :value="ft">
                  {{ $t(`operations.${ft}`) }}
                </option>
              </select>
            </th>
            <th>
              <div>{{ $t("admins_log.details") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.details"
              />
            </th>
            <th>
              <div>{{ $t("admins_log.created_at") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.created_at"
              />
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(log_row, index) in dataList"
              v-bind:key="log_row.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
              }"
            >
              <td>{{ log_row.id }}</td>
              <td v-if="hasPrivilege('system_ownership')">
                {{ log_row.user.email }}
              </td>
              <td>{{ $t(`operations.${log_row.operation}`) }}</td>
              <td :class="{ rtl: $i18n.locale == 'ar' }">
                {{ log_row.details }}
              </td>
              <td>{{ formatDate(log_row.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>
<script>
import PaginationRow from "../components/PaginationRow.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import axios from "axios";
import moment from "moment";
import { hasPrivilege } from "../global_constants.js";
import { mapGetters } from "vuex";
export default {
  name: "AdminsLog",
  components: { PaginationRow, LoadingOverlay },
  data() {
    return {
      dataList: null,
      admins: null,
      meta: { last_page: 0 },
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        id: "",
        user_id: -1,
        operation: "",
        details: "",
        created_at: "",
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
      operations: [
        "account_create",
        "account_create_cost",
        "account_expiration",
        "account_password",
        "account_sessions",
        "account_username",
        "account_allowed_sessions",
        "account_update",
        "account_delete",
        "account_disconnect",
        "account_enable",
        "account_disable",
        "account_renew",
        "account_renew_cost",
        "account_renew48",
        "account_renew48_free",
        "admins_add",
        "admins_update",
        "admins_delete",
        "admins_password",
        "resellers_add",
        "resellers_money",
        "card_design_save",
        "cards_generate",
        "cards_generate_cost",
        "card_password",
        "card_expiration",
        "card_allowed_sessions",
        "card_edit",
        "card_delete",
        "cards_delete",
        "card_clear_sessions",
        "card_disconnect",
        "card_enable",
        "card_disable",
        "mikrotik_services_update",
        "nas_create",
        "nas_update",
        "nas_delete",
        "nas_api_commit",
        "notify_account",
        "notify_card",
        "notify_profile",
        "notify_nas",
        "notify_ssr",
        "notifications_archive",
        "profiles_create",
        "profile_update",
        "profile_delete",
        "profile_disable",
        "profile_enabled",
        "profiles_delete",
        "session_disconnect",
        "settings_update",
        "database_backup",
        "support_ticket_delete",
        "support_ticket_update",
        "support_ticket_create",
        "database_backup_auto",
        "admin_login",
        "reseller_login",
        "bill_pay",
        "bill_delete",
      ],
    };
  },
  async created() {
    this.applyFilters();
    this.fetchAdmins();
  },
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "adminslog?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.user_id != -1)
        requestString += `&user_id=${this.filters.user_id}`;
      if (this.filters.operation != "")
        requestString += `&operation=${this.filters.operation}`;
      if (this.filters.details != "")
        requestString += `&details=${this.filters.details}`;
      if (this.filters.created_at != "")
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchAdmins() {
      this.loading = true;
      const response = await axios.get("adminslog/admins");
      if (response.status == 200) {
        this.admins = response.data.data;
      }
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD hh:mm a");
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
  },
};
</script>
